define("@3p-future-solutions/ember-cui/helpers/string-contains", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringContains = stringContains;

  /* istanbul ignore file */
  function stringContains(params
  /*, hash*/
  ) {
    return params[0].toLocaleLowerCase().includes(params[1]);
  }

  var _default = (0, _helper.helper)(stringContains);

  _exports.default = _default;
});