define("@3p-future-solutions/ember-cui/instance-initializers/body-class", ["exports", "@ember/application", "@ember/object/evented", "@ember/routing/route", "@3p-future-solutions/ember-cui/util/classUtils"], function (_exports, _application, _evented, _route, _classUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  /* istanbul ignore file */
  // import ApplicationInstance from '@ember/application/instance';
  var config = require("ember-get-config").default;

  /**
   * Automatic application of the .cui body class
   *
   * @event body-class
   */
  function
    /*appInstance: ApplicationInstance*/
  initialize() {
    _route.default.reopen({
      init: function init() {
        this._super.apply(this, arguments);
      },
      addClasses: (0, _evented.on)('activate', function () {
        this._applyBodyClass();
      }),
      _applyBodyClass: function _applyBodyClass() {
        var _getOwner$lookup = (0, _application.getOwner)(this).lookup('service:-document'),
            body = _getOwner$lookup.body;

        var cuiConfig = {
          applyCuiClassToBody: true,
          additionalBodyClasses: [],
          theme: null
        };

        if (config['ember-cui'] !== undefined) {
          cuiConfig = config['ember-cui'];
        }

        if (cuiConfig.applyCuiClassToBody) {
          (0, _classUtils.addClass)(body, 'cui');
        }

        if (cuiConfig.additionalBodyClasses && cuiConfig.additionalBodyClasses.length > 0) {
          for (var i = 0; i < cuiConfig.additionalBodyClasses.length; i++) {
            (0, _classUtils.addClass)(body, cuiConfig.additionalBodyClasses[i]);
          }
        }

        if (cuiConfig.theme) {
          body.setAttribute('data-theme', cuiConfig.theme);
        }
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});