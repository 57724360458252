define("@3p-future-solutions/ember-cui/helpers/html-safe", ["exports", "@ember/template", "@ember/component/helper"], function (_exports, _template, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HtmlSafe = HtmlSafe;
  _exports.default = void 0;

  /* istanbul ignore file */
  // @ts-ignore
  function HtmlSafe(params) {
    // @ts-ignore
    return new _template.htmlSafe(params.join(''));
  }

  var _default = (0, _helper.helper)(HtmlSafe);

  _exports.default = _default;
});