define("@3p-future-solutions/ember-cui/helpers/open-modal", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.openModal = openModal;

  /* istanbul ignore file */
  function openModal(params) {
    var detach = function detach(node) {
      node.parentElement.removeChild(node);
    };

    return function () {
      var node = document.getElementById(params[0]);
      var placeholder = document.createElement('div');
      placeholder.id = params[0] + '-placeholder';
      node.parentElement.insertBefore(placeholder, node);
      detach(node);

      if (params[1]) // the ID into which we inject the modal itself
        {
          document.getElementById(params[1]).append(node);
        } else {
        document.body.append(node);
      }

      node.classList.remove('hide');
      node.classList.add('show');
    };
  }

  var _default = (0, _helper.helper)(openModal);

  _exports.default = _default;
});