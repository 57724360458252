define("@3p-future-solutions/ember-cui/helpers/alert", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alert = alert;
  _exports.default = void 0;

  /* istanbul ignore file */
  function alert(params
  /*, hash*/
  ) {
    return function () {
      window.alert(params);
    };
  }

  var _default = (0, _helper.helper)(alert);

  _exports.default = _default;
});