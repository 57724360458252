define("@3p-future-solutions/ember-cui/helpers/p-close-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = closeModal;

  /* istanbul ignore file */
  function closeModal(params) {
    var detach = function detach(node) {
      node.parentElement.removeChild(node);
    };

    return function () {
      var node = document.getElementById(params[0]);
      detach(node);
      node.classList.add('hide');
      node.classList.remove('show');
      var placeholder = document.getElementById(params[0] + '-placeholder');
      placeholder.parentElement.insertBefore(node, placeholder);
      detach(placeholder);
    };
  }
});