define("@3p-future-solutions/ember-cui/util/classUtils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addClass = addClass;
  _exports.removeClass = removeClass;

  /* istanbul ignore file */
  function addClass(element, classToAdd) {
    var existingClasses = element.getAttribute('class') || '';

    if (existingClasses) {
      var classes = existingClasses.split(' ');

      if (classes.includes(classToAdd)) {
        return;
      }

      element.setAttribute('class', "".concat(existingClasses, " ").concat(classToAdd));
    } else {
      element.setAttribute('class', classToAdd);
    }
  }

  function removeClass(element, classToRemove) {
    var existingClass = element.getAttribute('class');
    element.setAttribute('class', existingClass.replace(classToRemove, ''));
  }
});