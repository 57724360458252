define("@3p-future-solutions/ember-cui/helpers/close-modal", ["exports", "@ember/component/helper", "@3p-future-solutions/ember-cui/helpers/p-close-modal"], function (_exports, _helper, _pCloseModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* istanbul ignore file */
  var _default = (0, _helper.helper)(_pCloseModal.default);

  _exports.default = _default;
});